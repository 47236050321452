import React from 'react';
import NavBar from '../Components.jsx/NavBar';


const AboutUs = () => {

    return (
        <div className='aboutUsContainer'>
        <h1 className='shipSimplyLogo'>Ship Simply.</h1>
            <NavBar />
            <div className='aboutUsText'>
                <h2>About Us</h2>
                <p>
                    Welcome to Ship Simply! We are a full-service logistics company that specializes in handling the transportation and delivery of vehicles, furniture, recreational vehicles and any other oversized or specialized items. Our team of experts has years of experience in the industry and is dedicated to providing our customers with the highest level of service.
                </p>
                <p>
                    We understand that every shipment is unique, which is why we offer customized solutions to meet the specific needs of our clients. Our state-of-the-art technology and logistics network allows us to provide real-time tracking and efficient delivery for all of your cargo.
                </p> 
                <p>
                    At Ship Simply, we pride ourselves on our commitment to safety, reliability and affordability. Our team is dedicated to ensuring that your cargo is handled with the utmost care and delivered on time, every time.
                </p>
                <p>
                    Thank you for considering Ship Simply for all of your logistics needs. We look forward to working with you and exceeding your expectations.
                </p>
            </div>
        </div>
    );
};

export default AboutUs;



