import React, { useState } from 'react';
import GoToMapBtn from '../Components.jsx/GoToMapBtn';

const StuffInput = ({setMapBtnTrue={setMapBtnTrue},  sendStuffInfoObject={sendStuffInfoObject}}) => {

    const setStuffInfoFunction = () => {
      sendStuffInfoObject(stuffInfo);
      const requiredFields = [
          'description', 'length', 'width', 'height',
          'weight'
      ];
      const isValid = requiredFields.every(field => stuffInfo[field] !== '');
      if (isValid) {
          setMapBtnTrue();
      } else {
          const missingFields = requiredFields.filter(field => stuffInfo[field] === '');
          alert(`You are missing: ${missingFields.join(', ')}`);
      }
  }

    const [stuffInfo, setStuffInfo] = useState({
        description: '',
        length: '',
        width: '',
        height: '',
        weight: '',
      });
    
      function handleDescriptionChange(e) {
        setStuffInfo({
          ...stuffInfo,
          description: e.target.value
        });
      }
      function handleLengthChange(e) {
        setStuffInfo({
          ...stuffInfo,
          length: e.target.value
        });
      }
      function handleWidthChange(e) {
        setStuffInfo({
          ...stuffInfo,
          width: e.target.value
        });
      }
      function handleHeightChange(e) {
        setStuffInfo({
          ...stuffInfo,
          height: e.target.value
        });
      }
      function handleWeightChange(e) {
        setStuffInfo({
          ...stuffInfo,
          weight: e.target.value
        });
      }

    return (
    <React.Fragment>
        <div className='stuffInputContainer'>
            <div className='inputForm'><h3>Description:</h3></div><input className='vehicleInput' type='text' onChange={handleDescriptionChange} />
            <div className='inputForm'><h3>Length (feet):</h3></div> <input className='vehicleInput' type='text' onChange={handleLengthChange} />
            <div className='inputForm'><h3>Width (feet):</h3></div> <input className='vehicleInput' type='text' onChange={handleWidthChange} />
            <div className='inputForm'><h3>Height (feet):</h3></div> <input className='vehicleInput' type='text' onChange={handleHeightChange} />
            <div className='inputForm'><h3>Weight (lbs):</h3></div> <input className='vehicleInput' type='text' onChange={handleWeightChange} />
        </div>
        <GoToMapBtn setMapBtnTrue={setMapBtnTrue} setStuffInfoFunction={setStuffInfoFunction} />
    </React.Fragment>
    );
};

export default StuffInput;











