import React, { useState, forwardRef, useImperativeHandle } from 'react';
import Autocomplete from "react-google-autocomplete";
import Geocode from "react-geocode";
// import Tooltip from 'npm install @material-ui/core/Tooltip';

Geocode.setApiKey("AIzaSyD6GLBRhQWQ3Kubf7jD4_DqI-bMo5R7d8Y");
Geocode.setLanguage("en");

const AddressSearch = forwardRef((props, _ref) =>  {
// const AddressSearch = (({setOriginClickOn, setDestinationClickOn}) =>  {

const [originAddress, setOriginAddress] = useState([]);
const [destinationAddress, setDestinationAddress] = useState([]);

useImperativeHandle(_ref, () => ({
    getLatLng: () => {
        return [originAddress, destinationAddress]
    }
}));

const getLatLngFromAddress = (format, address) => {
    Geocode.fromAddress(format).then(
        (response) => {
          const origin = response.results[0].formatted_address
          const { lat, lng } = response.results[0].geometry.location;
          address([lat, lng, origin]);
        },
        (error) => {
          console.error(error);
        }
      );
}

    return (
        <div className='addressView'>
            <div className='addressContainers'>
                <div className='originDiv'>
                    <label>
                    Origin City <br />
                    <div className='addressMarkerBtn'>
                        <Autocomplete
                            apiKey={'AIzaSyD6GLBRhQWQ3Kubf7jD4_DqI-bMo5R7d8Y'}
                            className='addressInput'
                            onPlaceSelected={(place) => {
                                getLatLngFromAddress(place.formatted_address, setOriginAddress)
                            }}
                            options={{
                                fields: ["place_id", "geometry", "formatted_address", "name"],
                                componentRestrictions: { country: "ca" },
                            }}
                        />
                    </div>
                    </label>
                </div>
                <div>
                    <label>
                    Destination City <br />
                    <div className='addressMarkerBtn'>
                        <Autocomplete
                            apiKey={'AIzaSyD6GLBRhQWQ3Kubf7jD4_DqI-bMo5R7d8Y'}
                            className='addressInput'
                            onPlaceSelected={(place) => {
                                getLatLngFromAddress(place.formatted_address, setDestinationAddress)
                            }}
                            options={{
                                fields: ["place_id", "geometry", "formatted_address", "name"],
                                componentRestrictions: { country: "ca" },
                            }}
                        />
                    </div>
                    </label>
                </div>
            </div>
        </div>
    );
});


export default AddressSearch;


