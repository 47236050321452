import React, { useState, useRef } from 'react'
// import { BrowserRouter as Router, Link, Route, Switch } from 'react-router-dom';
import { Route, Routes } from "react-router-dom"
import NavBar from './Components.jsx/NavBar';
// import { useJsApiLoader, GoogleMap, LoadScript, Marker, Polyline, DirectionsService, DirectionsRenderer } from '@react-google-maps/api';
// import AllTypesCard from './Radio Forms/AllTypesCard';
// import VehicleTypesCard from './Radio Forms/VehicleTypesCard'
// import BoatType from './Radio Forms/BoatTypesCard';
// import RecVehicleType from './Radio Forms/RecTypesCard';
// import TrailerType from './Radio Forms/TrailerTypesCard';
// import StuffType from './Radio Forms/StuffTypesCard';
// import TrailerInput from './Input Folder/TrailerInput';
// import VehicleInput from './Input Folder/VehicleInput';
// import RecInput from './Input Folder/RecInput';
// import BoatInput from './Input Folder/BoatInput'
// import StuffInput from './Input Folder/StuffInput';
// import AddressSearch from './AddressSearch';
// import { Dna } from 'react-loader-spinner'
// import axios from 'axios';
// import QuoteGenerator from './pages/QuoteGenerator';
import HomePage from './pages/HomePage';
import QuoteGenerator from './pages/QuoteGenerator';
import AboutUs from './pages/AboutUs';

function MapComponent() {

  return (

    // <QuoteGenerator />

    <Routes>
      <Route path="/" element={<HomePage />} />
      <Route path="/quoteGenerator" element={<QuoteGenerator />} />
      <Route path="/aboutUs" element={<AboutUs />} />
    </Routes>

    // <Router>
    // {/* <QuoteGenerator /> */}
    //   <Routes>
    //   <Route path="/homePage" element={<HomePage />} >
    //     {/* <HomePage /> */}
    //   </Route>
    //   </Routes>
    // </Router>

  )
}

export default React.memo(MapComponent)