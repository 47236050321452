import React, {useState} from 'react';
import GoToMapBtn from '../Components.jsx/GoToMapBtn';

const RecInput = ({setMapBtnTrue={setMapBtnTrue}, sendRecVehicleInfoObject={sendRecVehicleInfoObject}}) => {

    const setRecvehicleInfoFunction = () => {
      sendRecVehicleInfoObject(recVehicleInfo);
      const requiredFields = [
          'year', 'make', 'model', 'driveable',
          'trailer'
      ];
      const isValid = requiredFields.every(field => recVehicleInfo[field] !== '');
      if (isValid) {
          setMapBtnTrue();
      } else {
          const missingFields = requiredFields.filter(field => recVehicleInfo[field] === '');
          alert(`You are missing: ${missingFields.join(', ')}`);
      }
  }

    const [recVehicleInfo, setRecVehicleInfo] = useState({
        year: '',
        make: '',
        model: '',
        driveable: '',
        trailer: '',
      });
    
      function handleYearChange(e) {
        setRecVehicleInfo({
          ...recVehicleInfo,
          year: e.target.value
        });
      }
      function handleMakeChange(e) {
        setRecVehicleInfo({
          ...recVehicleInfo,
          make: e.target.value
        });
      }
      function handleModelChange(e) {
        setRecVehicleInfo({
          ...recVehicleInfo,
          model: e.target.value
        });
      }
      function handleDriveableChange(e) {
        setRecVehicleInfo({
          ...recVehicleInfo,
          driveable: e.target.value
        });
      }
      function handleTrailerChange(e) {
        setRecVehicleInfo({
          ...recVehicleInfo,
          trailer: e.target.value
        });
      }

    return (
    <React.Fragment>
        <div className='recInputContainer'>
            <div className=''>
            <div className='inputForm'><h3>Year:</h3></div> <input value={recVehicleInfo.year} className='vehicleInput' type='text' onChange={handleYearChange} />
                <div className='inputForm'><h3>Make:</h3></div><input value={recVehicleInfo.make} className='vehicleInput' type='text' onChange={handleMakeChange} />
                <div className='inputForm'><h3>Model:</h3></div> <input value={recVehicleInfo.model} className='vehicleInput' type='text' onChange={handleModelChange} />
                <div className='inputRadioSmall' style={{fontSize:15}}><h3 className='vehicleInputRadioTitle'>Driveable:</h3> 
                    <div className='cardSmall'>
                        <input
                        type="radio" 
                        name="driveable" 
                        className="radioBtn"
                        value="yes"
                        onChange={handleDriveableChange}
                        />
                        <label className='cardLabel' for="card1">
                            <p className='buttonText'>Yes</p>
                        </label>
                    </div>
                    <div className='cardSmall'>
                        <input
                        type="radio" 
                        name="driveable" 
                        className="radioBtn"
                        value="no"
                        onChange={handleDriveableChange}
                        />
                        <label className='cardLabel' for="card1">
                            <p className='buttonText'>No</p>
                        </label>
                    </div>
                </div>
                <div className='inputRadioSmall' style={{fontSize:15}}><h3 className='vehicleInputRadioTitle'>Trailer Included:</h3> 
                    <div className='cardSmall'>
                        <input
                        type="radio" 
                        name="recTrailer" 
                        className="radioBtn"
                        value="yes"
                        onChange={handleTrailerChange}
                        />
                        <label className='cardLabel' for="card1">
                            <p className='buttonText'>Yes</p>
                        </label>
                    </div>
                    <div className='cardSmall'>
                        <input
                        type="radio" 
                        name="recTrailer" 
                        className="radioBtn"
                        value="no"
                        onChange={handleTrailerChange}
                        />
                        <label className='cardLabel' for="card1">
                            <p className='buttonText'>No</p>
                        </label>
                    </div>
                </div>
            </div>
        </div>
        <GoToMapBtn setMapBtnTrue={setMapBtnTrue} setRecvehicleInfoFunction={setRecvehicleInfoFunction} />
    </React.Fragment>
    );
};

export default RecInput;






