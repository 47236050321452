import React, { useState, forwardRef, useImperativeHandle } from 'react';

const AllTypesCard = forwardRef((props, _ref) => {

    const [vehicleType, setVehicleType] = useState([
        {type: "Car", img: require('../images/carImg.png'), page:2}, {type: "SUV", img: require('../images/suv.png'), page:2}, {type: "Truck", img: require('../images/pickup.png'), page:2}
      ])

      const [radioValue, setRadioValue] = useState(0);
      const onChange = (ev) => {
        //save your value here with state variable
        console.log(ev.target.value);
        setRadioValue(ev.target.value);
      };

      useImperativeHandle(_ref, () => ({
        getTheVehicleType: () => {
            return radioValue
        }
    }));

    return (
    <div className='shippingItemsContainer'>
        {vehicleType.map((item) => (
        <div className='card'>
            <input
            type="radio" 
            name="pricing" 
            value={item.type}
            className="radioBtn"
            onChange={onChange}
            />
            <label className='cardLabel' for="card1">
            <p className='buttonText'>{item.type}</p>
            </label>
            <img className='carImg' src={item.img} />
        </div>
        ))}
        </div>
    );
});

export default AllTypesCard;






