import React, { useState } from 'react';
import { Route, Routes, Link } from "react-router-dom"

const NavBar = () => {

    return (

        <div className='navBar'>
            <Link to="/">
            <li>
                Home
            </li>
            </Link>
            <Link to="/quoteGenerator">
            <li>
                Quote
            </li>
            </Link>
            <Link to="/">
            <li>
                Services
            </li>
            </Link>
            <Link to="/aboutUs">
            <li>
                About Us
            </li>
            </Link>

      </div>

    );
};

export default NavBar;










