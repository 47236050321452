import React, { useState } from 'react';
import GoToMapBtn from '../Components.jsx/GoToMapBtn';

const BoatInput = ({setMapBtnTrue={setMapBtnTrue}, sendBoatInfoObject={sendBoatInfoObject}}) => {

    const setBoatInfoFunction = () => {
      sendBoatInfoObject(boatInfo);
      const requiredFields = [
          'year', 'make', 'model', 'trailer', 'length', 'width', 'height', 'weight'
      ];
      const isValid = requiredFields.every(field => boatInfo[field] !== '');
      if (isValid) {
          setMapBtnTrue();
      } else {
          const missingFields = requiredFields.filter(field => boatInfo[field] === '');
          alert(`You are missing: ${missingFields.join(', ')}`);
      }
  }

    const [boatInfo, setBoatInfo] = useState({
        year: '',
        make: '',
        model: '',
        trailer: '',
        length: '',
        width: '',
        height: '',
        weight: '',
      });
    
      function handleYearChange(e) {
        setBoatInfo({
          ...boatInfo,
          year: e.target.value
        });
      }
      function handleMakeChange(e) {
        setBoatInfo({
          ...boatInfo,
          make: e.target.value
        });
      }
      function handleModelChange(e) {
        setBoatInfo({
          ...boatInfo,
          model: e.target.value
        });
      }
      function handleTrailerChange(e) {
        setBoatInfo({
          ...boatInfo,
          trailer: e.target.value
        });
      }
      function handleLengthChange(e) {
        setBoatInfo({
          ...boatInfo,
          length: e.target.value
        });
      }
      function handleWidthChange(e) {
        setBoatInfo({
          ...boatInfo,
          width: e.target.value
        });
      }
      function handleHeightChange(e) {
        setBoatInfo({
          ...boatInfo,
          height: e.target.value
        });
      }
      function handleWeightChange(e) {
        setBoatInfo({
          ...boatInfo,
          weight: e.target.value
        });
      }

    return (
    <div className='boatInputContainer'>
        <div className='trailerInputLeft'>
            <div className='trailerInputForm'><h3>Year:</h3></div> <input className='trailerInput' type='text' onChange={handleYearChange} />
            <div className='trailerInputForm'><h3>Make:</h3></div><input className='trailerInput' type='text' onChange={handleMakeChange} />
            <div className='trailerInputForm'><h3>Model:</h3></div> <input className='trailerInput' type='text' onChange={handleModelChange} />
            <div className='inputRadioSmall' style={{fontSize:15}}><h3 className='vehicleInputRadioTitle'>Trailer Included:</h3> 
                <div className='cardSmall'>
                    <input
                    type="radio" 
                    name="boatTrailer" 
                    className="radioBtn"
                    value="yes"
                    onChange={handleTrailerChange}
                    />
                    <label className='cardLabel' for="card1">
                        <p className='buttonText'>Yes</p>
                    </label>
                </div>
                <div className='cardSmall'>
                    <input
                    type="radio" 
                    name="boatTrailer" 
                    className="radioBtn"
                    value="no"
                    onChange={handleTrailerChange}
                    />
                    <label className='cardLabel' for="card1">
                        <p className='buttonText'>No</p>
                    </label>
                </div>
            </div>
        </div>
        <div className='trailerInputRight'>
            <div className='trailerInputForm'><h3>Length (feet):</h3></div> <input className='trailerInput' type='text' maxlength="3" onChange={handleLengthChange} />
            <div className='trailerInputForm'><h3>Width (feet):</h3></div> <input className='trailerInput' type='text' maxlength="2" onChange={handleWidthChange} />
            <div className='trailerInputForm'><h3>Height (feet):</h3></div> <input className='trailerInput' type='text' maxlength="2" onChange={handleHeightChange} />
            <div className='trailerInputForm'><h3>Weight (lbs):</h3></div> <input className='trailerInput' type='text' maxlength="5" onChange={handleWeightChange} />
        </div>
        <GoToMapBtn setMapBtnTrue={setMapBtnTrue} setBoatInfoFunction={setBoatInfoFunction} />
    </div>
    );
};

export default BoatInput;










