import React from 'react';

const GoToMapBtn = ({setMapBtnTrue, setVehicleInfoFunction, setRecvehicleInfoFunction, setBoatInfoFunction, setTrailerInfoFunction, setStuffInfoFunction }) => {

let infoObjectCallbackFunction;

if(setVehicleInfoFunction) {
    infoObjectCallbackFunction = setVehicleInfoFunction
}
if(setRecvehicleInfoFunction) {
    infoObjectCallbackFunction = setRecvehicleInfoFunction
}
if(setBoatInfoFunction) {
    infoObjectCallbackFunction = setBoatInfoFunction
}
if(setTrailerInfoFunction) {
    infoObjectCallbackFunction = setTrailerInfoFunction
}
if(setStuffInfoFunction) {
    infoObjectCallbackFunction = setStuffInfoFunction
}

    return (
    <div>
        <button 
        className='goToMapBtn'
        onClick={
            (
            setMapBtnTrue,
            infoObjectCallbackFunction
            )}
        >Go To Map</button>
    </div>
    );
};

export default GoToMapBtn;










