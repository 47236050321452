import React, { useState } from 'react';
import GoToMapBtn from '../Components.jsx/GoToMapBtn';

const TrailerInput = ({setMapBtnTrue={setMapBtnTrue},  sendTrailerInfoObject={sendTrailerInfoObject}}) => {

  const setTrailerInfoFunction = () => {
    sendTrailerInfoObject(trailerInfo);
    const requiredFields = [
        'year', 'make', 'model', 'length',
        'width', 'height', 'weight', 'axles', 'hookup', 'towable'
    ];
    const isValid = requiredFields.every(field => trailerInfo[field] !== '');
    if (isValid) {
        setMapBtnTrue();
    } else {
        const missingFields = requiredFields.filter(field => trailerInfo[field] === '');
        alert(`You are missing: ${missingFields.join(', ')}`);
    }
}

    const [trailerInfo, setTrailerInfo] = useState({
        year: '',
        make: '',
        model: '',
        length: '',
        width: '',
        height: '',
        weight: '',
        axles: '',
        hookup: '',
        towable: '',
      });
    
      function handleYearChange(e) {
        setTrailerInfo({
          ...trailerInfo,
          year: e.target.value
        });
      }
      function handleMakeChange(e) {
        setTrailerInfo({
          ...trailerInfo,
          make: e.target.value
        });
      }
      function handleModelChange(e) {
        setTrailerInfo({
          ...trailerInfo,
          model: e.target.value
        });
      }
      function handleLengthChange(e) {
        setTrailerInfo({
          ...trailerInfo,
          length: e.target.value
        });
      }
      function handleWidthChange(e) {
        setTrailerInfo({
          ...trailerInfo,
          width: e.target.value
        });
      }
      function handleHeightChange(e) {
        setTrailerInfo({
          ...trailerInfo,
          height: e.target.value
        });
      }
      function handleWeightChange(e) {
        setTrailerInfo({
          ...trailerInfo,
          weight: e.target.value
        });
      }
      function handleAxlesChange(e) {
        setTrailerInfo({
          ...trailerInfo,
          axles: e.target.value
        });
      }
      function handleHookupChange(e) {
        setTrailerInfo({
          ...trailerInfo,
          hookup: e.target.value
        });
      }
      function handleTowableChange(e) {
        setTrailerInfo({
          ...trailerInfo,
          towable: e.target.value
        });
      }

    return (
    <div className='trailerInputContainer'>      
        <div className='trailerInputLeft'>
            <div className='trailerInputForm'><h3>Year:</h3></div> <input className='trailerInput' type='text' onChange={handleYearChange} />
            <div className='trailerInputForm'><h3>Make:</h3></div><input className='trailerInput' type='text' onChange={handleMakeChange} />
            <div className='trailerInputForm'><h3>Model:</h3></div> <input className='trailerInput' type='text' onChange={handleModelChange} />
            <div className='trailerInputForm'><h3>Length (feet):</h3></div> <input className='trailerInput' type='text' onChange={handleLengthChange} />
            <div className='trailerInputForm'><h3>Width (feet):</h3></div> <input className='trailerInput' type='text' onChange={handleWidthChange} />
        </div>
        <div className='trailerInputRight'>
            <div className='trailerInputForm'><h3>Height (feet):</h3></div> <input className='trailerInput' type='text' onChange={handleHeightChange} />
            <div className='trailerInputForm'><h3>Weight (lbs):</h3></div> <input className='trailerInput' type='text' onChange={handleWeightChange} />
            <div className='trailerInputForm'><h3>Axles:</h3></div> 
            <select className='trailerInputSelect' onChange={handleAxlesChange} >
                <option value="1">1</option>
                <option value="2">2</option>
                <option value="3">3</option>
                <option value="4">4+</option>
            </select>
            <div className='trailerInputForm'><h3>Hookup:</h3></div> 
            <select className='trailerInputSelect' onChange={handleHookupChange} >
                <option value="Bumper" selected>-- Select --</option>
                <option value="Bumper">Bumper</option>
                <option value="5th Wheel">5th Wheel</option>
                <option value="Gooseneck">Gooseneck</option>
            </select>
            <div className='inputRadioSmall' style={{fontSize:15}}><h3 className='vehicleInputRadioTitle'>Towable:</h3> 
                <div className='cardSmall'>
                    <input
                    type="radio" 
                    name="trailerTowable" 
                    className="radioBtn"
                    value="yes"
                    onChange={handleTowableChange}
                    />
                    <label className='cardLabel' for="card1">
                        <p className='buttonText'>Yes</p>
                    </label>
                </div>
                <div className='cardSmall'>
                    <input
                    type="radio" 
                    name="trailerTowable" 
                    className="radioBtn"
                    value="no"
                    onChange={handleTowableChange}
                    />
                    <label className='cardLabel' for="card1">
                        <p className='buttonText'>No</p>
                    </label>
                </div>
            </div>
        </div>
        <GoToMapBtn setMapBtnTrue={setMapBtnTrue} setTrailerInfoFunction={setTrailerInfoFunction} />
    </div>
    );
};

export default TrailerInput;






