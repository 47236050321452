import React, { useState, useef } from 'react';
import NavBar from '../Components.jsx/NavBar';
import Zoom from 'react-reveal/Zoom';
import Fade from 'react-reveal/Fade';
import Bounce from 'react-reveal/Bounce';
import Tada from 'react-reveal/Tada';
import Jump from 'react-reveal/Jump';
import { Route, Routes, Link } from "react-router-dom"

const HomePage = () => {

    return (

        <div className='homePageContainer'>
            <h1 className='shipSimplyLogoHome'>Ship Simply.</h1>
            <p className='shipSimplyLogoHomeVolta'>A Volta Company</p>
            <div className='homePageHero'>
                <img className='mapImg' src={require('../images/map.jpeg')} />
                <NavBar />
                {/* <img className='gearImg' src={require('./images/settings.png')} /> */}
                <h2 className='homePageHeader moveItem'>Ship Simply</h2>
                {/* <p className='slogan'>Don't turn shipping your stuff into rocket science.</p> */}
                <p className='slogan'><Fade delay={500}>You have something to ship, we have someone to ship it.</Fade></p>
                <Link to="/quoteGenerator">
                    <button className='quoteBtn'>
                        Get Your Instant Quote
                    </button>
                </Link>
                {/* <div className='boxContainer'></div> */}
                {/* <Globe
                    backgroundColor='#011022'
                    className='globe'
                    globeImageUrl="//unpkg.com/three-globe/example/img/earth-night.jpg"
                    arcsData={arcsData}
                    arcColor={'color'}
                    arcDashLength={() => Math.random()}
                    arcDashGap={() => Math.random()}
                    arcDashAnimateTime={() => Math.random() * 4000 + 2000}
                    >
                </Globe> */}
                </div>
            {/* <div className='globe'></div> */}
            <div className='homePage'>
                <Zoom>
                    <h1>WE SHIP IT ALL</h1>
                    <ul>
                        <li>Instant quotes</li> 
                        <li>Expert services</li> 
                        <li>Tracking options</li> 
                    </ul>
                    </Zoom>
            </div>
            <div className='homePage1' >
                <Fade right>
                    <div className='homePageBoxes'>
                        <h3>Vehicle Transport</h3>
                    </div>
                    <div className='homePageBoxes'>
                        <h3>Recreational Transport</h3>
                    </div>
                    <div className='homePageBoxes'>
                        <h3>Trailer Transport</h3>
                    </div>
                    <div className='homePageBoxes'>
                        <h3>Furniture</h3>
                    </div>
                    <div className='homePageBoxes'>
                        <h3>ATV</h3>
                    </div>
                    <div className='homePageBoxes'>
                        <h3>Bicycles</h3>
                    </div>
                    <div className='homePageBoxes'>
                        <h3>Pianos</h3>
                    </div>
                </Fade>
            </div>
            <div className='homePage2'>
            <Link to="/quoteGenerator">
                <Tada>
                    <button className='quoteBtn2 blinkMe'>Get Your Instant Quote</button>
                </Tada>
            </Link>
            </div>
            <div className='homePage3'>
                <Fade left>
                    <h1>All your shipments in one place.</h1>
                    <ul>
                        <li>Moving houses<span>?</span></li>
                        <li>Shipping your car<span>?</span></li>
                        <li>Buying a boat, trailer or new toy<span>?</span></li>
                        <li>Don't own a pickup to move that couch<span>?</span></li>
                    </ul></Fade>
                    <Fade delay={1200}><h1>We can do it all.</h1></Fade>
            </div>
            <div className='homePage4Container'>
                <p>
                <img className='checkboxImg' src={require('../images/done.png')} />
                    Boxes 
                    <img className='checkboxImg' src={require('../images/done.png')} />
                    Vehicles
                    <img className='checkboxImg' src={require('../images/done.png')} />
                    Furniture
                    <img className='checkboxImg' src={require('../images/done.png')} />
                    Trailers
                    <img className='checkboxImg' src={require('../images/done.png')} />
                    ATV's
                    <img className='checkboxImg' src={require('../images/done.png')} />
                    RV's
                    <img className='checkboxImg' src={require('../images/done.png')} />
                    Tractors
                    <img className='checkboxImg' src={require('../images/done.png')} />
                    Dirtbikes
                    <img className='checkboxImg' src={require('../images/done.png')} />
                    Boats
                    <img className='checkboxImg' src={require('../images/done.png')} />
                    Snowmobiles
                </p>
                <div className='homePage4'>
                    <Bounce>
                        <div className='roundIcon'>
                            <img className='boxesImg' src={require('../images/blackBox.png')} />
                        </div>
                    </Bounce>
                    <Bounce>
                        <div className='roundIcon'>
                            <img src={require('../images/shipping.png')} />
                        </div>
                    </Bounce>
                    <Bounce>
                        <div className='roundIcon'>
                            <img className='furnitureImg' src={require('../images/furn.png')} />
                        </div>
                    </Bounce>
                    <Bounce>
                        <div className='roundIcon'>
                            <img src={require('../images/trailer.png')} />
                        </div>
                    </Bounce>
                    <Bounce>
                        <div className='roundIcon'>
                            <img src={require('../images/speedboat.png')} />
                        </div>
                    </Bounce>
                    <Bounce>
                        <div className='roundIcon'>
                            <img src={require('../images/quad.png')} />
                        </div>
                    </Bounce>
                </div>
            </div>
            <div className='homePage5'>
                <img className='computerShippingImg moveItem' src={require('../images/compShipping.jpg')} />
                        <Zoom>
                            <h1>Manage all of your shipments in one place.</h1>
                        </Zoom>
                    {/* <Fade left>
                        <h1>Don't Stress</h1>
                        <h1>Let us handle the heavy lifting</h1>
                    </Fade> */}
            </div>
            <Fade><h4 className='homePage5Text'>One convenient platform to find a solution to all of your shipping needs. </h4></Fade>
            <div className='homePage6'>
                <div className='homePage6Header'>
                    <h1>So how does it work?</h1>
                </div>
                <div className='homePage6Img'>
                    <div>
                        <Fade delay={100}>
                            <img className='' src={require('../images/team.png')} /> 
                        </Fade>
                        <h4>Building Trust</h4>
                        Leveraging relationships with a variety of service providers across all industries.
                    </div>
                    <div>
                        <Fade delay={200}>
                            <img className='' src={require('../images/statistics.png')} /> 
                            <h4>Performance Analytics</h4>
                            In house software built on cloud computing to keep track of your shipment and the transporters performance.
                        </Fade>
                    </div>
                    <div>
                        <Fade delay={300}>
                            <img className='' src={require('../images/gold-medal.png')} /> 
                            <h4>Award Winning Service</h4>
                            Experienced employees with over 10 years in the industry.
                        </Fade>
                    </div>
                    <div>
                        <Fade delay={400}>
                            <img className='' src={require('../images/chip.png')} /> 
                            <h4>AI Powered Dispatch</h4>
                            Optimizing routes and lowering our carbon footprint by powering our dispatching through AI and machine learning.
                        </Fade>
                    </div>
                    <div>
                        <Fade delay={500}>
                            <img className='' src={require('../images/best-price.png')} /> 
                            <h4>Competitve Pricing</h4>
                            We crawl the web for the best prices, so you don't have to.
                        </Fade>
                    </div>
                </div>
            </div>
            <div className='homePage7'>
                <img className='searchImg moveItem' src={require('../images/search.jpg')} />
                <div className='homePage7Text'>
                    <Zoom>
                        <div>
                            Stop The  
                        </div>   
                        <div>                   
                            <Jump>Endless</Jump>
                        </div>
                        <div>
                            Search.
                        </div>
                        <Tada>
                            <button className='quoteBtn3 blinkMe'>Get Your Instant Quote</button>
                         </Tada>
                    </Zoom>
                </div>
                {/* <Zoom><h4>Our user friendly system takes a description of your item and finds the best price, with the best service provider in seconds. Confirm the price and receive confirmation from one of our team members.</h4></Zoom> */}
            </div>
            <div className='footer'>
                <h3>&copy; ShipSimply 2023</h3>
            </div>
        </div>

    );
};

export default HomePage;










