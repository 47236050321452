import React, { useState, forwardRef, useImperativeHandle } from 'react';

const RecVehicleType = forwardRef((props, _ref) => {

    const [shippingItem, setShippingItem] = useState([
        {type: "ATV", img: require('../images/quad.png')}, {type: "Dirtbike", img: require('../images/dirtbike.png')}, {type: "Snowmobile", img: require('../images/snowmobile.png')}
      ])

      const [radioValue, setRadioValue] = useState(0);
      const onChange = (ev) => {
        //save your value here with state variable
        console.log(ev.target.value);
        setRadioValue(ev.target.value);
      };

      useImperativeHandle(_ref, () => ({
        getTheVehicleType: () => {
            return radioValue
        }
    }));

    return (
    <div className='shippingItemsContainer'>
        {shippingItem.map((item) => (
        <div className='card'>
            <input
            type="radio" 
            name="pricing" 
            value={item.type}
            className="radioBtn"
            onChange={onChange}
            />
            <label className='cardLabel' for="card1">
            <p className='buttonText'>{item.type}</p>
            </label>
            <img className='carImg' src={item.img} />
        </div>
        ))}
        </div>
    );
});

export default RecVehicleType;






