import React, { useState, forwardRef, useImperativeHandle } from 'react';

const AllTypesCard = forwardRef((props, _ref) => {

    const [shippingItem, setShippingItem] = useState([
        {type: "Vehicle", img: require('../images/carImg.png'), page:1}, {type: "Rec Vehicle", img: require('../images/atv.png'), page:1}, {type: "Boat", img: require('../images/yatch.png'), page:1}, {type: "Trailer", img: require('../images/trailer.png'), page:1}, {type: "Stuff", img: require('../images/box.png'), page:1}
      ])

      const [radioValue, setRadioValue] = useState("");
      const onChange = (ev) => {
        //save your value here with state variable
        console.log(ev.target.value);
        setRadioValue(ev.target.value);
      };

      useImperativeHandle(_ref, () => ({
        getTheVehicleType: () => {
            return radioValue
        }
    }));

    return (
    <div className='shippingItemsContainer'>
        {shippingItem.map((item) => (
        <div className='card'>
            <input
            type="radio" 
            name="pricing" 
            value={item.type}
            className="radioBtn"
            onChange={onChange}
            />
            <label className='cardLabel' for="card1">
            <p className='buttonText'>{item.type}</p>
            </label>
            <img className='carImg' src={item.img} />
        </div>
        ))}
        </div>
    );
});

export default AllTypesCard;






