import React, { useState, useRef, useEffect } from 'react'
import { GoogleMap, LoadScript, Marker, Polyline } from '@react-google-maps/api';
// import { poly } from '@google/maps';
import AllTypesCard from '../Radio Forms/AllTypesCard';
import VehicleTypesCard from '../Radio Forms/VehicleTypesCard'
import BoatType from '../Radio Forms/BoatTypesCard';
import RecVehicleType from '../Radio Forms/RecTypesCard';
import TrailerType from '../Radio Forms/TrailerTypesCard';
import StuffType from '../Radio Forms/StuffTypesCard';
import TrailerInput from '../Input Folder/TrailerInput';
import VehicleInput from '../Input Folder/VehicleInput';
import RecInput from '../Input Folder/RecInput';
import BoatInput from '../Input Folder/BoatInput'
import StuffInput from '../Input Folder/StuffInput';
import AddressSearch from '../AddressSearch';
import { Dna } from 'react-loader-spinner'
import axios from 'axios';
import { Route, Routes, Link } from "react-router-dom"
import * as turf from '@turf/turf'
import Confetti from 'react-confetti'

const google = window.google;
let addressState;
let vehicleTypeState;

const containerStyle = {
  width: '100%',
  height: '100vh',
};

const center = {  
  lat: 53.242,
  lng: -96.222
}

let matchedRouteArray = [];

function QuoteGenerator() {

  useEffect(() => {
    getOgr();
    getRoutes();
    getPolygons();
    setShowBackButton(false)
    }, [])

    // ################################

    const [polygonCoordinates, setPolygonCoordinates] = useState("");
    const [polygonCoordinatesFormatted, setPolygonCoordinatesFormatted] = useState("");
    const [theOgr, setTheOgr] = useState("")
    const [theOriginOgr, setTheOriginOgr] = useState("")
    const [theDestinationOgr, setTheDestinationOgr] = useState("")
    const [route, setRoute] = useState("")
    const [maxPrice, setMaxPrice] = useState("0")
    const [ libraries ] = useState(['places', 'geometry']);
    const [ mapApi, setMapApi ] = useState('');

    // const [routeObj, setRouteObj] = useState("")

    const getOgr = () => {
      axios.get('https://ship-simply.herokuapp.com/ogr')
      .then((res) => {
        setTheOgr(res.data)
      })
      .catch(err => {
          console.error(err.response.data);    // ***
          console.error(err.response.status);  // ***
      })
    }

    const getRoutes = () => {
      axios.get('https://ship-simply.herokuapp.com/routes')
      .then((res) => {
        setRoute(res.data)
        for (var i = 0; i < res.data.length; i++){
          var routePair = Object.values(res.data[i])
          const routeObj = {
            route: routePair[1] + " - " + routePair[2],
            vehiclePrice:routePair[3],
            vehiclePriceNonRunner:routePair[4],
            recPrice:routePair[5],
            recPriceNoTrailer:routePair[6],
            boatPrice:routePair[7],
            boatPriceNoTrailer:routePair[8],
            trailerPrice:routePair[9]
          }
          matchedRouteArray.push(routeObj)
        }

      })
      .catch(err => {
          console.error(err.response.data);    // ***
          console.error(err.response.status);  // ***
      })

    }

    const getPolygons = () => {

      var myArray = [];
      let latLngCoordinates = [];

      axios.get('https://ship-simply.herokuapp.com/polygons')
      .then((res) => {
      setPolygonCoordinates(res.data);
      
      for (var i = 0; i < res.data.length; i++){
        var polyObjectValues = Object.values(res.data[i])
        polyObjectValues = polyObjectValues.shift()
        polyObjectValues = polyObjectValues.replace('POLYGON','')
        polyObjectValues = polyObjectValues.replace('((','')
        polyObjectValues = polyObjectValues.replace('))','')
        var nameArr = polyObjectValues.split(',');

        myArray.push(nameArr)

      }

      myArray.forEach(coordArr => {
          let coord = []
          coordArr.forEach(point => {
              let latlng = point.split(" ")
              coord.push([parseFloat(latlng[0]), parseFloat(latlng[1])])
          })
          latLngCoordinates.push(coord)
      })
      setPolygonCoordinatesFormatted(latLngCoordinates)
      })
      .catch(err => {
          console.error(err.response.data);    // ***
          console.error(err.response.status);  // ***
      })
  };

    function insidePolygon2() {

      const originIndexArray = [];
      const destinationIndexArray = [];
      const indexRouteArray = [];
      const matchedRoutePrices = [];

    polygonCoordinatesFormatted.forEach((polygonCoordinate, i) => {

      var bermudaTriangle = turf.polygon([polygonCoordinate])

      var ptOrigin = turf.point([addressState[0][0], addressState[0][1]]);
      var ptDestination = turf.point([addressState[1][0], addressState[1][1]]);
      
      if (turf.booleanPointInPolygon(ptOrigin, bermudaTriangle)) {
        originIndexArray.push(theOgr[i].ogr);
      }
      if (turf.booleanPointInPolygon(ptDestination, bermudaTriangle)) {
        destinationIndexArray.push(theOgr[i].ogr);
      }

    });

      originIndexArray.forEach((origin) => {
        destinationIndexArray.forEach((destination) => {
          const routeIndex = origin + " - " + destination;
          indexRouteArray.push(routeIndex);
        });
      });

      // CREATE ARRAY OR VARIABLE TO HANDLE WHEN YOU DONT WANT TO QUOTE A PRICE.. AND RETURN "CONTACT US"
      indexRouteArray.forEach((route) => {
        matchedRouteArray.forEach((matchedRoute) => {
          if (route === matchedRoute.route) {
            if(vehicleTypeState==="Car" || vehicleTypeState==="SUV" || vehicleTypeState==="Truck") {
              if(vehicleInfoObject.driveable === "yes" && vehicleInfoObject.modified ==="no"){
                matchedRoutePrices.push(matchedRoute.vehiclePrice);
              } else {
                matchedRoutePrices.push(matchedRoute.vehiclePriceNonRunner);
              }
            } 
            if (vehicleTypeState==="ATV" || vehicleTypeState==="Dirtbike" || vehicleTypeState==="Snowmobile") {
              if(recVehicleInfoObject.trailer ==="no") {
                matchedRoutePrices.push(matchedRoute.recPriceNoTrailer);
              } else {
                matchedRoutePrices.push(matchedRoute.recPrice);
              }
            } 
            if (vehicleTypeState==="Power" || vehicleTypeState==="Sail") {
              if(boatInfoObject.trailer ==="no") {
                matchedRoutePrices.push(matchedRoute.boatPriceNoTrailer);
              } else { 
                matchedRoutePrices.push(matchedRoute.boatPrice);
              }
            } 
          } 

        });
      });

    if(vehicleTypeState==="Car" || vehicleTypeState==="SUV" || vehicleTypeState==="Truck") {
      if(vehicleInfoObject.driveable === "no" || vehicleInfoObject.modified === "yes") {
        setMaxPrice(Math.max(...matchedRoutePrices))
      }
      if(vehicleTypeState === "Car") {
        setMaxPrice(Math.max(...matchedRoutePrices))
      }
      else if(vehicleTypeState === "SUV") {
      setMaxPrice(Math.max(...matchedRoutePrices)+75)
      }
      else if(vehicleTypeState === "Truck") {
      setMaxPrice(Math.max(...matchedRoutePrices)+125)
      } else {
        setMaxPrice("Contact Us")
      }
    } 

    if(vehicleTypeState==="ATV" || vehicleTypeState==="Dirtbike" || vehicleTypeState==="Snowmobile") {
      setMaxPrice(Math.max(...matchedRoutePrices))
    } 

    if(vehicleTypeState==="Power" || vehicleTypeState==="Sail") {
      if(boatInfoObject.length < 30 && boatInfoObject.width < 9 && boatInfoObject.height < 20 && boatInfoObject.weight < 5000) {
        setMaxPrice(Math.max(...matchedRoutePrices))
      } else if (boatInfoObject.length > 30 && boatInfoObject.length < 40 && boatInfoObject.width < 9 && boatInfoObject.height < 20 && boatInfoObject.weight < 10000) {
      setMaxPrice(Math.max(...matchedRoutePrices)*1.5)
      } else if (boatInfoObject.length > 40 || boatInfoObject.width < 10 || boatInfoObject.height > 20 || boatInfoObject.weight < 12000) {
        setMaxPrice("Contact Us")
        }
      }
    }

  const submitForm = (event) => {

    event.preventDefault()
    
    if(currentStateValue === "Car" || currentStateValue === "SUV" || currentStateValue === "Truck") {
    
    axios.post('https://ship-simply.herokuapp.com/vehicleRequest', {
      type: currentStateValue, 
      year: vehicleInfoObject.year, 
      make: vehicleInfoObject.make, 
      model: vehicleInfoObject.model, 
      driveable: vehicleInfoObject.driveable, 
      modified: vehicleInfoObject.modified,
      origin: originName,
      destination: destinationName,
      price: maxPrice,
      name: contactName,
      email: contactEmail,
      phone: contactPhone,
      orderNumber: orderNumber
    }).then(()=> {
      console.log("SUCCESS!")
    });
  } else {
    console.log("request error")
  }
    if(currentStateValue === "ATV" || currentStateValue === "Dirtbike" || currentStateValue === "Snowmobile") {
    
    axios.post('https://ship-simply.herokuapp.com/recVehicleRequest', {
      type: currentStateValue, 
      year: recVehicleInfoObject.year, 
      make: recVehicleInfoObject.make, 
      model: recVehicleInfoObject.model, 
      driveable: recVehicleInfoObject.driveable, 
      trailer: recVehicleInfoObject.trailer,
      origin: originName,
      destination: destinationName,
      price: maxPrice,
      name: contactName,
      email: contactEmail,
      phone: contactPhone,
      orderNumber: orderNumber
    }).then(()=> {
      console.log("SUCCESS!")
    });
  } else {
    console.log("request error")
  }
    if(currentStateValue === "Power" || currentStateValue === "Sail") {
    
    axios.post('https://ship-simply.herokuapp.com/boatRequest', {
      type: currentStateValue, 
      year: boatInfoObject.year, 
      make: boatInfoObject.make, 
      model: boatInfoObject.model, 
      trailer: boatInfoObject.trailer,
      length: boatInfoObject.length,
      width: boatInfoObject.width,
      height: boatInfoObject.height,
      weight: boatInfoObject.weight,
      origin: originName,
      destination: destinationName,
      price: maxPrice,
      name: contactName,
      email: contactEmail,
      phone: contactPhone,
      orderNumber: orderNumber
    }).then(()=> {
      console.log("SUCCESS!")
    });
  } else {
    console.log("request error")
  }
    if(currentStateValue === "Travel Trailer" || currentStateValue === "Flat Deck" || currentStateValue === "Dump Trailer") {
    
    axios.post('https://ship-simply.herokuapp.com/trailerRequest', {
      type: currentStateValue, 
      year: trailerInfoObject.year, 
      make: trailerInfoObject.make, 
      model: trailerInfoObject.model, 
      length: trailerInfoObject.length,
      width: trailerInfoObject.width,
      height: trailerInfoObject.height,
      weight: trailerInfoObject.weight,
      axles: trailerInfoObject.axles,
      hookup: trailerInfoObject.hookup,
      towable: trailerInfoObject.towable,
      origin: originName,
      destination: destinationName,
      price: maxPrice,
      name: contactName,
      email: contactEmail,
      phone: contactPhone,
      orderNumber: orderNumber
    }).then(()=> {
      console.log("SUCCESS!")
    });
  } else {
    console.log("request error")
  }
    if(currentStateValue === "Boxes" || currentStateValue === "Furniture" || currentStateValue === "Other") {
    
    axios.post('https://ship-simply.herokuapp.com/stuffRequest', {
      type: currentStateValue, 
      description: stuffInfoObject.description, 
      length: stuffInfoObject.length, 
      width: stuffInfoObject.width, 
      height: stuffInfoObject.height,
      weight: stuffInfoObject.weight,
      origin: originName,
      destination: destinationName,
      price: maxPrice,
      name: contactName,
      email: contactEmail,
      phone: contactPhone,
      orderNumber: orderNumber
    }).then(()=> {
      console.log("SUCCESS!")
    });
  } else {
    console.log("request error")
  }
  setFormSubmitted(true);
  };

  const addressSearchRef = useRef();
  const vehicleTypeRef = useRef();

  const getAddressState = () => {
    addressState = addressSearchRef.current.getLatLng()
      setOriginLat(addressState[0][0]);
      setOriginLng(addressState[0][1]);
      setDestinationLat(addressState[1][0]);
      setDestinationLng(addressState[1][1]);
      setOriginName(addressState[0][2])
      setDestinationName(addressState[1][2])
    return addressState
  }

  const getVehType = () => {
    vehicleTypeState = vehicleTypeRef.current.getTheVehicleType()
    if(!vehicleTypeState) {
      alert("You must select an option")
    } else {
      setCurrentState(vehicleTypeState);
      setCurrentStateValue(vehicleTypeState)
      }
    return vehicleTypeState
    }

  if(vehicleTypeState === 'Rec Vehicle') {
  }


  const allTypes = <AllTypesCard ref={vehicleTypeRef} />

  const [markers, setMarker] = useState([]);
  const [originLat, setOriginLat] = useState("");
  const [originLng, setOriginLng] = useState("");
  const [destinationLat, setDestinationLat] = useState("");
  const [destinationLng, setDestinationLng] = useState("");
  const [originString, setOriginString] = useState("");
  const [destinationString, setDestinationString] = useState("");
  const [originClick, setOriginClick] = useState(false);
  const [destinationClick, setDestinationClick] = useState(false);
  const [vehicleType, setVehicleType] = useState("");
  const [map, setMap] = useState(/** @type google.maps.Map */ (''));
  const [directionsResponse, setDirectionsResponse] = useState(null);
  const [currentStateValue, setCurrentStateValue] = useState(null);
  const [goToMap, setGoToMap] = useState(true);
  const [currentState, setCurrentState] = useState(allTypes);
  const [showRateSpinner, setShowRateSpinner] = useState(false);
  const [mapBtnSelected, setMapBtnSelected] = useState(false);
  const [showRatePage, setShowRatePage] = useState(false);
  const [showInputPage, setShowInputPage] = useState(false);
  const [vehicleInfoObject, setVehicleInfoObject] = useState('');
  const [recVehicleInfoObject, setRecVehicleInfoObject] = useState('');
  const [boatInfoObject, setBoatInfoObject] = useState('');
  const [trailerInfoObject, setTrailerInfoObject] = useState('');
  const [stuffInfoObject, setStuffInfoObject] = useState('');
  const [contactName, setContactName] = useState('');
  const [contactEmail, setContactEmail] = useState('');
  const [contactPhone, setContactPhone] = useState('');
  const [originName, setOriginName] = useState('');
  const [destinationName, setDestinationName] = useState('');
  const [orderNumber, setOrderNumber] = useState('');
  const [showConfetti, setShowConfetti] = useState(false);
  const [formSubmitted, setFormSubmitted] = useState(false);
  const [showBackButton, setShowBackButton] = useState(false)

  let ratesItemInfo;
  let ratesItemInfo2;

  const setOriginClickOn = (data) => {
    setOriginClick(data)
    setDestinationClick(false)
    console.log("origin clicked")
  }

  const setDestinationClickOn = () => {
    setDestinationClick(true)
    setOriginClick(false)
    console.log("destination clicked")
  }

  const createOrderNumber = () => {
    setOrderNumber(Math.floor(100000 + Math.random() * 900000))
  }

  const sendVehicleInfoObject = (info) => {
    setVehicleInfoObject(info)
    createOrderNumber()
  }
  
  const sendRecVehicleInfoObject = (info) => {
    setRecVehicleInfoObject(info)
    createOrderNumber()
  }
  const sendBoatInfoObject = (info) => {
    setBoatInfoObject(info)
    createOrderNumber()
  }

  const sendTrailerInfoObject = (info) => {
    setTrailerInfoObject(info)
    createOrderNumber()
  }

  const sendStuffInfoObject = (info) => {
    setStuffInfoObject(info)
    createOrderNumber()
  }

  const setMapBtnTrue = () => {
// TODO: SET INPUT VALIDATION TO PREVENT USER FROM GOING TO MAP WITHOUT ENTERING ITEM INFO
    if(1==1) {
    setMapBtnSelected(true)
    setCurrentState(null)
    } else {
      alert("not yet!")
    }
  }

  const showInputFormPage = () => {
    setShowConfetti(false)
    setShowInputPage(true)
    setShowRatePage(false)
  }

  // test function to demonstrate future function to get rates
  const hideLoadingRates = () => {
    setTimeout(function() {   
      setMapBtnSelected(false)  
      // why do i need this?
      // setCurrentStateValue(null)
      setShowRatePage(true)
      setShowBackButton(false)
      setShowRateSpinner(false)
    }, 1000);
  }

  /** @type React.MutableRefObject<HTMLInputElement> */
  const originRef = useRef()
  /** @type React.MutableRefObject<HTMLInputElement> */
  const destinationRef = useRef()

  const onMapClick = (e) => {

    if(originClick) {
      
    setMarker((current) => [
      ...current,
      {
        lat: e.latLng.lat(),
        lng: e.latLng.lng()
      }
    ]);
    setOriginLat(e.latLng.lat())
    setOriginLng(e.latLng.lng())
  }
    if(destinationClick) {
      
    setMarker((current) => [
      ...current,
      {
        lat: e.latLng.lat(),
        lng: e.latLng.lng()
      }
    ]);
    setDestinationLat(e.latLng.lat())
    setDestinationLng(e.latLng.lng())
  }
  };

  console.log(currentState, currentStateValue)

  const polyLineCoords= [
    {lat: originLat, lng: originLng},
    {lat: destinationLat, lng: destinationLng}
  ];

  // const coord1 = new google.maps.LatLng(26.194876675795218,-69.8291015625)
  const coord1 = { lat: -34.397, lng: 150.644 };
  // const coordinate = new google.maps.LatLng(37.782, -122.419);

  const draw = () => {
    getAddressState();
    if(addressState[0] == '' || addressState[1] == '') {
      alert("You must select an option from the search dropdown")
    } else {
    setShowConfetti(true)
    setShowRateSpinner(true);
    hideLoadingRates();
    insidePolygon2();
    }
  }

  const getVehicleStateType = () => {
    getVehType()
    setShowBackButton(true)
  }

  const setTheState = (type, component) => {
    {currentState === type && setCurrentState(component)}
  }  
  const theBackButton = (type, component) => {
    {currentStateValue === type && setCurrentState(component)}
    setCurrentStateValue("")
  }  

  // check value of selected radio button and navigate to that component on "next" button click
  setTheState('Vehicle',<VehicleTypesCard ref={vehicleTypeRef} />)
  setTheState('Rec Vehicle',<RecVehicleType ref={vehicleTypeRef} />)
  setTheState('Boat',<BoatType ref={vehicleTypeRef} />)
  setTheState('Trailer',<TrailerType ref={vehicleTypeRef} />)
  setTheState('Stuff',<StuffType ref={vehicleTypeRef} />)

  // check value of selected radio button and navigate to that component on "next" button click
  if(currentState === 'Car' || currentState === 'SUV' || currentState === 'Truck') {
    setCurrentState(<VehicleInput setMapBtnTrue={setMapBtnTrue}  sendVehicleInfoObject={sendVehicleInfoObject} />)
    setGoToMap(false)

  }
  if(currentState === 'ATV' || currentState === 'Dirtbike' || currentState === 'Snowmobile') {
    setCurrentState(<RecInput setMapBtnTrue={setMapBtnTrue}  sendRecVehicleInfoObject={sendRecVehicleInfoObject} />)
    setGoToMap(false)
  }
  if(currentState === 'Power' || currentState === 'Sail') {
    setCurrentState(<BoatInput setMapBtnTrue={setMapBtnTrue} sendBoatInfoObject={sendBoatInfoObject} />)
    setGoToMap(false)
  }
  if(currentState === 'Travel Trailer' || currentState === 'Flat Deck' || currentState === 'Dump Trailer') {
    setCurrentState(<TrailerInput setMapBtnTrue={setMapBtnTrue} sendTrailerInfoObject={sendTrailerInfoObject} />)
    setGoToMap(false)
  }
  if(currentState === 'Boxes' || currentState === 'Furniture' || currentState === 'Other') {
    setCurrentState(<StuffInput setMapBtnTrue={setMapBtnTrue} sendStuffInfoObject={sendStuffInfoObject} />)
    setGoToMap(false)
  }

  const setRatesItemInfo = (theObject) => {
    ratesItemInfo = 
    <div className="vehicleInfoRatePage">
      <table className='itemTable'>
        <tbody>
          <tr>
            <td className='vehicleInfoTableRow' align="center" colSpan="3">
              {theObject.year} &nbsp;
              {theObject.make} &nbsp;
              {theObject.model}
            </td>
          </tr>
          <tr>
            <td>{originName}</td>
          </tr>
          <tr>
            <td>
              <img className='arrowDownImg' src={require('../images/arrow-down.png')} />
            </td>
          </tr>
          <tr>
            <td>{destinationName}</td>
          </tr>
        </tbody>
      </table>
    </div>
  }

  const setRatesItemInfo2 = (theObject) => {
    ratesItemInfo2 = 
    <div className='ratesItemInfo2'>
      <div className='margin10'>
        <b>Vehicle:</b>  
        {theObject.year} {theObject.make} {theObject.model}
      </div>
      <div className='margin10'>
        <b>Origin:</b> 
        {originName}
      </div>
      <div className='margin10'>
        <b>Destination:</b>
      {destinationName}
      </div>
      <div className='margin10'>
        <b>Price:</b>
        ${maxPrice}
      </div>
    </div>
  }

  const setStuffRatesItemInfo = () => {
    ratesItemInfo = 
    <div className="vehicleInfoRatePage">
      <h4>{stuffInfoObject.description}</h4>
    </div>
  }
  
  const setStuffRatesItemInfo2 = () => {
    ratesItemInfo2 = 
    <div className="vehicleInfoRatePage">
      <h4>{stuffInfoObject.description}</h4>
    </div>
  }

  const ShowRates = () => {
    return (
      <div className="confirmRate">
        {ratesItemInfo}
        <div className="rateInfoDiv">
          <h2>Your Rate</h2>
          <h2>${maxPrice}</h2>
        </div>
        <button
         className='confirmRateBtn'
         onClick={showInputFormPage}
         >Confirm This Rate</button>
      </div>
    )
  }

  const CurrentStateDiv = () => {
    return (
    <div className="shippingItemInfo">
    {showBackButton ? 
      <button 
      className='inputBackBtn'
      onClick={backButton}
      >Back</button> : null}
    {goToMap ? <button 
      className='inputNextBtn'
      onClick={
        getVehicleStateType
      }
      >Next</button> :
      null }
    {currentState}
  </div>
    )
  } 

  const SubmittedFormPage = () => {
    return (
      <div className='submittedFormPage'>
        <h1>Thank You!</h1>
        <h2>We will be in touch with you soon.</h2>
        <h3>Order Number: {orderNumber}</h3>
        <div>
          {ratesItemInfo2}
        </div>
        <div>
          <Link style={{ textDecoration: 'none' }} to="/">
            <button className='submittedFormBtns'>Go Back Home</button>
          </Link>
          <button className='submittedFormBtns' onClick={()=>refreshQuotePage()}>Receive Another Quote</button>
        </div>
      </div>
    )
  }

  const refreshQuotePage = () => {
    setFormSubmitted(false)
    setShowInputPage(false)
    setCurrentState(<AllTypesCard ref={vehicleTypeRef} />)
    setCurrentStateValue("")
    setGoToMap(true)
  }

  if(vehicleInfoObject) {
    setRatesItemInfo(vehicleInfoObject)
    setRatesItemInfo2(vehicleInfoObject)
  }
  if(recVehicleInfoObject) {
    setRatesItemInfo(recVehicleInfoObject)
    setRatesItemInfo2(recVehicleInfoObject)
  }
  if(boatInfoObject) {
    setRatesItemInfo(boatInfoObject)
    setRatesItemInfo2(boatInfoObject)
  }
  if(trailerInfoObject) {
    setRatesItemInfo(trailerInfoObject)
    setRatesItemInfo2(trailerInfoObject)
  }
  if(stuffInfoObject) {
    setStuffRatesItemInfo()
    setStuffRatesItemInfo2()
  }

  // navigate back to previous page by checking value of current page
  const backButton = () => {
    theBackButton('Vehicle',allTypes)
    theBackButton('Rec Vehicle',allTypes)
    theBackButton('Boat',allTypes)
    theBackButton('Trailer',allTypes)
    theBackButton('Stuff',allTypes)
    if(currentStateValue === 'Car' || currentStateValue === 'SUV' || currentStateValue === 'Truck') {
      setCurrentState(<VehicleTypesCard ref={vehicleTypeRef} />)
      setCurrentStateValue("Vehicle")
      setGoToMap(true)
    }
    if(currentStateValue === 'ATV' || currentStateValue === 'Dirtbike' || currentStateValue === 'Snowmobile') {
      setCurrentState(<RecVehicleType ref={vehicleTypeRef} />)
      setCurrentStateValue("Rec Vehicle")
      setGoToMap(true)
    }
    if(currentStateValue === 'Power' || currentStateValue === 'Sail') {
      setCurrentState(<BoatType ref={vehicleTypeRef} />)
      setCurrentStateValue("Boat")
      setGoToMap(true)
    }
    if(currentStateValue === 'Travel Trailer' || currentStateValue === 'Flat Deck' || currentStateValue === 'Dump Trailer') {
      setCurrentState(<TrailerType ref={vehicleTypeRef} />)
      setCurrentStateValue("Trailer")
      setGoToMap(true)
    }
    if(currentStateValue === 'Boxes' || currentStateValue === 'Furniture' || currentStateValue === 'Other') {
      setCurrentState(<StuffType ref={vehicleTypeRef} />)
      setCurrentStateValue("Stuff")
      setGoToMap(true)
    }
  }

  return (

  <div className='mainPage'>
  {formSubmitted ?  <Link to="/"><h1 className='shipSimplyLogo'>Ship Simply.</h1></Link> : null}
  {formSubmitted ? <SubmittedFormPage /> :
    <div>
      <div className='navbarMap'>
        <Link style={{ textDecoration: 'none' }} to="/">
          <h3>Home</h3>
        </Link>
      </div>
      <LoadScript
        googleMapsApiKey="AIzaSyD6GLBRhQWQ3Kubf7jD4_DqI-bMo5R7d8Y"
        libraries={libraries}
      >
      {mapBtnSelected ? <button 
        className='goBtn'
        onClick={draw}
      >Go</button> : null}
      {mapBtnSelected ? <AddressSearch ref={addressSearchRef} setOriginClickOn={setOriginClickOn} setDestinationClickOn={setDestinationClickOn} /> : null}
        <GoogleMap
          mapContainerStyle={containerStyle}
          center={center}
          zoom={4.5}
          onLoad={map=>setMap(map)}
          onClick={onMapClick}
        >
        {mapBtnSelected ? null : <CurrentStateDiv />}
        {showRateSpinner ? <Polyline
          path={polyLineCoords}
          options={{
            geodesic: true,
            strokeColor: 'purple',
            strokeOpacity: 1.0,
            strokeWeight: 2,
          }}
          /> : null }
        {showRateSpinner ? 
        <div>
        <Marker 
          icon={{
          url: (require('../images/homeBlue.png')),
          fillColor: '#EB00FF',
          }}
          position={{ 
            lat: originLat,
            lng: originLng 
          }} /> 
        <Marker 
          icon={{
          url: (require('../images/red-flag.png')),
          fillColor: '#EB00FF',
          }}
          position={{ 
            lat: destinationLat,
            lng: destinationLng 
          }} /> 
        </div>
          : null }
        </GoogleMap>
        {showRateSpinner ?
        <div>
        <Dna
          visible={true}
          height="180"
          width="80"
          ariaLabel="dna-loading"
          wrapperStyle={{}}
          wrapperClass="spinner"
        />
        <h3 className='spinnerText'>Loading Rates</h3>
        </div>
        : null}
        {showRatePage ? 
        <ShowRates /> : null}
        {showInputPage ? 
          <div className="confirmRateInput">
            <h2>Confirm Order</h2>
            <form>
              <input required className='nameInput' placeholder='Enter Your Full Name' name="name" value={contactName} onChange={(e) => setContactName(e.target.value)} />
              <input required className='phoneInput' placeholder='Enter Your Phone Number' name="phone" value={contactPhone} onChange={(e) => setContactPhone(e.target.value)} />
              <input required className='emailInput' placeholder='Enter Your Email' name="email" value={contactEmail} onChange={(e) => setContactEmail(e.target.value)} />{'\n'} 
              <input className='submitBtn' type="submit" onClick={submitForm} />
            </form>
          </div>
        : null}
        {showConfetti ? 
          <Confetti
            drawShape={ctx => {
              ctx.beginPath()
              for(let i = 0; i < 22; i++) {
                const angle = 0.35 * i
                const x = (0.2 + (1.5 * angle)) * Math.cos(angle)
                const y = (0.2 + (1.5 * angle)) * Math.sin(angle)
                ctx.lineTo(x, y)
              }
              ctx.stroke()
              ctx.closePath()
            }}
          />
        : null
        }
      </LoadScript>
    </div>}
  </div>
  )
}

export default React.memo(QuoteGenerator)