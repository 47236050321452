import React, { useState } from 'react';
import GoToMapBtn from '../Components.jsx/GoToMapBtn';

const VehicleInput = ({setMapBtnTrue={setMapBtnTrue}, sendVehicleInfoObject={sendVehicleInfoObject}}) => {

    const setVehicleInfoFunction = () => {
        sendVehicleInfoObject(vehicleInfo);
        const requiredFields = [
            'year', 'make', 'model', 'driveable', 'modified'
        ];
        const isValid = requiredFields.every(field => vehicleInfo[field] !== '');
        if (isValid) {
            setMapBtnTrue();
        } else {
            const missingFields = requiredFields.filter(field => vehicleInfo[field] === '');
            alert(`You are missing: ${missingFields.join(', ')}`);
        }
    }

    const [validated, setValidated] = useState(false)
    const [vehicleInfo, setVehicleInfo] = useState({
        year: '',
        make: '',
        model: '',
        driveable: '',
        modified: '',
      });
    
      function handleYearChange(e) {
        setVehicleInfo({
          ...vehicleInfo,
          year: e.target.value
        });
      }
      function handleMakeChange(e) {
        setVehicleInfo({
          ...vehicleInfo,
          make: e.target.value
        });
      }
      function handleModelChange(e) {
        setVehicleInfo({
          ...vehicleInfo,
          model: e.target.value
        });
      }
      function handleDriveableChange(e) {
        setVehicleInfo({
          ...vehicleInfo,
          driveable: e.target.value
        });
      }
      function handleModifiedChange(e) {
        setVehicleInfo({
          ...vehicleInfo,
          modified: e.target.value
        });
      }

let vehicleModelsArray = [
    {
        make:'ACURA',
        models:['INTEGRA','MDX','NSX','RDX','TLX','ILX','OTHER']
    },
    {
        make:'ALFA ROMEO',
        models:['GIULIA','STELVIO','TONALE','OTHER']
    },
    {
        make:'ASTON MARTIN',
        models:['DB','DBS','DBX','VANTAGE','OTHER']
    },
    {
        make:'AUDI',
        models:['A SERIES','E-TRON','Q3','Q5','Q7','S SERIES','TT','OTHER']
    },
    {
        make:'BENTLEY',
        models:['CONTINENTAL','FLYING SPUR','OTHER']
    },
    {
        make:'BMW',
        models:['2 SERIES','3 SERIES','4 SERIES','5 SERIES', '7 SERIES','8 SERIES', 'I3','I4','I7','M3','M4','M5','M8','X1','X2','X3','X4','X5','X6','X7','Z4','OTHER']
    },
    {
        make:'BUICK',
        models:['ENCLAVE','ENCORE','OTHER']
    },
    {
        make:'CADILLAC',
        models:['CT4','CT5','ESCALADE','XT','OTHER']
    },
    {
        make:'CHEVROLET',
        models:['BLAZER','BOLT','CAMARO','COLORADO','CORVETTE','EQUINOX','EXPRESS VAN','MALIBU','SILVERADO 1500','SILVERADO 2500','SILVERADO 3500','SPARK','SUBURBAN','TAHOE','TRAILBLAZER','TRAVERSE','TRAX','OTHER']
    },
    {
        make:'CHRYSLER',
        models:['200','300','PACIFICA','OTHER']
    },
    {
        make:'DODGE',
        models:['CHALLENGER','CHARGER','DURANGO','OTHER']
    },
    {
        make:'FIAT',
        models:['500','OTHER']
    },
    {
        make:'FORD',
        models:['BRONCO','ECOSPORT','EDGE','ESCAPE','EXPEDITION','EXPLORER','F150','F250','F350','MUSTANG','RANGER','TRANSIT CARGO VAN','TRANSIT PASSENGER VAN','OTHER']
    },
    {
        make:'GMC',
        models:['ACADIA','CANYON','HUMMER','SAVANA','SIERRA 1500','SIERRA 2500','SIERRA 3500','TERRAIN','YUKON','OTHER']
    },
    {
        make:'HONDA',
        models:['ACCORD','CIVIC','CR-V','HR-V','ODYSSEY','PILOT','RIDGELINE','OTHER']
    },
    {
        make:'HYUNDAI',
        models:['ACCENT','ELANTRA','IONIQ','KONA','PALISADE','SANTA CRUZ','SANTE FE','SONATA','TUSCON','VELOSTER','OTHER']
    },
    {
        make:'INFINITI',
        models:['Q50','Q60','Q80','OTHER']
    },
    {
        make:'JAGUAR',
        models:['E PACE','F PACE','F TYPE','I PACE','XF','OTHER']
    },
    {
        make:'JEEP',
        models:['CHEROKEE','COMPASS','GLADIATOR','GRAND CHEROKEE','GRAND WAGONEER','WRANGLER','OTHER']
    },
    {
        make:'KIA',
        models:['FORTE','NIRO','RIO','SEDONA','SORENTO','SOUL','SPORTAGE','STINGER','TELLURIDE','OTHER']
    },
    {
        make:'LAND ROVER',
        models:['DEFENDER','DISCOVERY','RANGE ROVER','OTHER']
    },
    {
        make:'LEXUS',
        models:['ES','GX','IS','LC','LS','LX','NX','RC','RX','OTHER']
    },
    {
        make:'LINCOLN',
        models:['AVIATOR','NAVIGATOR','OTHER']
    },
    {
        make:'MASERATI',
        models:['GHIBLI','QUATTROPORTE','OTHER']
    },
    {
        make:'MAZDA',
        models:['CX-3','CX-5','CX-7','CX-9','MAZDA3','MAZDA6','MX-5','OTHER']
    },
    {
        make:'MERCEDES-BENZ',
        models:['A-CLASS','C-CLASS','CLA','CLS','E-CLASS','G-CLASS','GLA','GLB','GLC','GLE','GLS','METRIS','S-CLASS','SPRINTER','OTHER']
    },
    {
        make:'MINI',
        models:['CLUBMAN','COUNTRYMAN','COUPE','OTHER']
    },
    {
        make:'MITSUBISHI',
        models:['ECLIPSE','MIRAGE','OUTLANDER','OTHER']
    },
    {
        make:'NISSAN',
        models:['ALTIMA','ARMADA','FRONTIER','GT-R','KICKS','LEAF','MAXIMA','MURANO','NV VAN','PATHFINDER','ROGUE','SENTRA','TITAN','VERSA','OTHER']
    },
    {
        make:'PONTIAC',
        models:['G6','G8','SOLSTICE','VIBE','OTHER']
    },
    {
        make:'PORSCHE',
        models:['911','CAYENNE','MACAN','PANAMERA','TAYCAN','OTHER']
    },
    {
        make:'RAM',
        models:['1500','2500','3500','PROMASTER VAN','PROMASTER CITY','OTHER']
    },
    {
        make:'ROLLS ROYCE',
        models:['GHOST','PHANTOM','WRAITH','OTHER']
    },
    {
        make:'SAAB',
        models:['9-3','OTHER']
    },
    {
        make:'SMART',
        models:['FORTWO']
    },
    {
        make:'SUBARU',
        models:['BRZ','CROSSTREK','FORESTER','IMPREZA','LEGACY','OUTBACK','WRX','OTHER']
    },
    {
        make:'SUZUKI',
        models:['AERIO','OTHER']
    },
    {
        make:'TESLA',
        models:['MODEL 3','MODEL S','MODEL X','MODEL Y','OTHER']
    },
    {
        make:'TOYOTA',
        models:['4RUNNER','86','AVALON','CH-R','CAMRY','COROLLA','SUPRA','HIGHLANDER','LAND CRUISER','MIRAI','PRIUS','RAV4','SEQUOIA','SIENNA','TACOMA','TUNDRA','VENZA','OTHER']
    },
    {
        make:'VOLKSWAGEN',
        models:['ATLAS','GOLF','GTI','JETTA','PASSAT','TIGUAN','OTHER']
    },
    {
        make:'VOLVO',
        models:['S60','S90','V60','V90','XC40','XC60','XC90','OTHER']
    },
]

let vehicleYearsArray = [
    '2023',
    '2022',
    '2021',
    '2020',
    '2019',
    '2018',
    '2017',
    '2016',
    '2015',
    '2014',
    '2013',
    '2012',
    '2011',
    '2010',
    '2009',
    '2008',
    '2007',
    '2006',
    '2005',
    '2004',
    '2003',
    '2002',
    '2001',
    '2000',
    '1999',
    '1998',
    '1997',
    '1996',
    '1995',
    '1994',
    '1993',
    '1992',
    '1991',
    'Older',
]

const modelArray = vehicleModelsArray.filter(make =>
    make.make === vehicleInfo.make)

    return (
    <React.Fragment>  
        <div className='vehicleInputContainer'>
            <div className='inputForm'>
                <h3>Year:</h3>
                    <select className='vehicleInput' onChange={handleYearChange} >
                        <option value="Select a Year"> -- select -- </option>
                        {vehicleYearsArray.map((year) => (
                        <option value={year}>{year}</option>
                        ))}
                    </select>
                    <div className='inputForm'><h3>Make:</h3></div>
                    <select required className='vehicleInput' onChange={handleMakeChange} >
                        <option value="Select a Make"> -- select -- </option>
                        {vehicleModelsArray.map(make => 
                        <option value={make.make}>{make.make}</option>
                        )}
                    </select>
                    <div className='inputForm'><h3>Model:</h3></div> 
                    <select className='vehicleInput' onChange={handleModelChange} >
                        <option value="Select a Model"> -- select -- </option>
                        {modelArray.map((model) => 
                            model.models.map(filteredModel => 
                            <option value={filteredModel}>{filteredModel}</option>
                            )
                        ) }
                    </select>
                    <div className='vehicleRadioBtns'>
                        <div className='inputRadioSmall' style={{fontSize:15}}><h3 className='vehicleInputRadioTitle'>Driveable:</h3> 
                            <div className='cardSmall'>
                                <input
                                type="radio" 
                                name="vehicleDriveable" 
                                className="radioBtn"
                                value="yes"
                                onChange={handleDriveableChange} 
                                />
                                <label className='cardLabel' for="card1">
                                    <p className='buttonText'>Yes</p>
                                </label>
                            </div>
                            <div className='cardSmall'>
                                <input
                                type="radio" 
                                name="vehicleDriveable" 
                                className="radioBtn"
                                value="no"
                                onChange={handleDriveableChange} 
                                />
                                <label className='cardLabel' for="card1">
                                    <p className='buttonText'>No</p>
                                </label>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className='modifiedRadioBtn'>
                <div className='inputRadioSmall' style={{fontSize:15}}><h3 className='vehicleInputRadioTitle'>Modified (Lifted, Lowered etc):</h3> 
                    <div className='cardSmall'>
                            <input
                            type="radio" 
                            name="pricing" 
                            className="radioBtn"
                            value="yes"
                            onChange={handleModifiedChange} 
                            />
                            <label className='cardLabel' for="card1">
                                <p className='buttonText'>Yes</p>
                            </label>
                        </div>
                        <div className='cardSmall'>
                            <input
                            type="radio" 
                            name="pricing" 
                            className="radioBtn"
                            value="no"
                            onChange={handleModifiedChange} 
                            />
                            <label className='cardLabel' for="card1">
                                <p className='buttonText'>No</p>
                            </label>
                        </div>
                    </div>
                </div>
        <GoToMapBtn setMapBtnTrue={setMapBtnTrue} setVehicleInfoFunction={setVehicleInfoFunction} />
    </React.Fragment>
    );
};

export default VehicleInput;






